import React from "react";
import LebanonImage from "../assets/Lebanon.webp";

export default function AboutUs() {
  return (
    <div className="relative bg-gray-900 py-24 sm:py-32">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
          Who We Are
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-300">
          We are Lebanese people who want to help our country and people find a
          house for rent or a dorm, a safe place in school or elsewhere, and to
          facilitate the search so that all the announcements are not lost in
          stories or other places. Our services are free, with no fees. You can
          directly add a post, and we are here to help.
        </p>
        <p className="mt-4 text-lg leading-8 text-gray-300">
          نحن لبنانيون نريد مساعدة بلدنا وشعبنا في العثور على منزل للإيجار أو
          سكن، مكان آمن في المدرسة أو في أماكن أخرى، وتسهيل البحث حتى لا تضيع
          جميع الإعلانات في المنشورات أو في أماكن أخرى. خدماتنا مجانية، دون أي
          رسوم. يمكنك إضافة منشور مباشرة، ونحن هنا للمساعدة
        </p>
        <p className="mt-6 text-xl font-semibold text-white">
          COOLNESS AND PEACE UPON LEBANON
        </p>
        <img
          src={LebanonImage}
          alt="Lebanon"
          className="mt-8 w-full max-w-md mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
}
