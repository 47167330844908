import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const TypeAPI = {
  getAllTypes: async function (cancel = false) {
    const response = await api.request({
      url: `/api/types`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAllTypes.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(TypeAPI);
