import React, { Suspense, useEffect } from "react";
import RoutesWenFiSakanApp from "./routes";
import { LinearProgress } from "@mui/material";
function App() {
  useEffect(() => {
    document.title = "WEN FI SAKAN";
  });
  return (
    <Suspense fallback={<LinearProgress />}>
      <RoutesWenFiSakanApp />
    </Suspense>
  );
}

export default App;
