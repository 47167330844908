import React, { useState, useEffect } from "react";
import { TypeAPI } from "../api/TypeAPI";
import { PostAPI } from "../api/PostAPI";
import { useNavigate } from "react-router-dom";
const statesData = {
  regions: [
    { value: "Aakkar", label: "Aakkar" },
    { value: "Aaley", label: "Aaley" },
    { value: "Baabda", label: "Baabda" },
    { value: "Baalbek", label: "Baalbek" },
    { value: "Batroun", label: "Batroun" },
    { value: "Bcharreh", label: "Bcharreh" },
    { value: "Bent Jbeil", label: "Bent Jbeil" },
    { value: "Beqaa Ouest", label: "Beqaa Ouest" },
    { value: "Beyrouth", label: "Beyrouth" },
    { value: "Chouf", label: "Chouf" },
    { value: "Hasbaiya", label: "Hasbaiya" },
    { value: "Hermel", label: "Hermel" },
    { value: "Jbeil", label: "Jbeil" },
    { value: "Jezzine", label: "Jezzine" },
    { value: "Kesrouane", label: "Kesrouane" },
    { value: "Koura", label: "Koura" },
    { value: "Marjaayoun", label: "Marjaayoun" },
    { value: "Matn", label: "Matn" },
    { value: "Minieh-Danniyeh", label: "Minieh-Danniyeh" },
    { value: "Nabatiyeh", label: "Nabatiyeh" },
    { value: "Rachaiya", label: "Rachaiya" },
    { value: "Saida", label: "Saida" },
    { value: "Sour", label: "Sour" },
    { value: "Tripoli", label: "Tripoli" },
    { value: "Zahleh", label: "Zahleh" },
    { value: "Zgharta", label: "Zgharta" },
  ],
};

const isValidLebaneseNumber = (str) => {
  const regex = /^(03|76|70|71|78|79|81|01|05|07|08|04|02|09|06)\d{6}$/;
  return regex.test(str);
};

const isValidEmail = (str) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(str);
};

const AddPost = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [typeId, setTypeId] = useState("");
  const [types, setTypes] = useState([]); // State for types
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [price, setPrice] = useState("");
  const [isFree, setIsFree] = useState(false);
  const navigate = useNavigate();
  const fetchTypes = async () => {
    try {
      const typesApi = await TypeAPI.getAllTypes();
      setTypes((types) => [...types, ...typesApi]);
    } catch (error) {
      console.error("Failed to fetch types:", error);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email.length > 0) {
      if (!isValidEmail(email)) {
        alert("Please enter a valid email address.");
        return;
      }
    }

    if (!isValidLebaneseNumber(phoneNumber)) {
      alert("Please enter a valid Lebanese phone number.");
      return;
    }

    alert("Post submitted successfully!");

    // setTitle("");
    // setDescription("");
    // setTypeId("");
    // setCity("");
    // setState("");
    // setEmail("");
    // setPhoneNumber("");
    // setPrice("");
    // setIsFree(false);
    callApi();
  };

  const callApi = async () => {
    const formData = new FormData();
    formData.append("_method", "post");
    formData.append("title", title);
    if (description.length != 0) {
      formData.append("description", description);
    }
    formData.append("city", city);
    formData.append("state", state);
    if (email.length != 0) {
      formData.append("email", email);
    }
    formData.append("phoneNumber", phoneNumber);
    formData.append("isFree", isFree);
    formData.append("type_id", typeId);
    if (isFree === false) {
      formData.append("price", price);
    }
    try {
      await PostAPI.create(formData).then((response) => {
        navigate("/all-posts");
      });
    } catch (error) {}
    // console.log(formData);
  };

  return (
    <div className="max-w-lg mx-auto p-4 pt-20">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block">
            Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full border p-2"
            maxLength="255"
          />
        </div>

        <div>
          <label className="block">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength="500"
            className="w-full border p-2"
            rows="4"
          />
          <div className="text-sm text-gray-500">
            {500 - description.length} characters remaining
          </div>
        </div>

        <div>
          <label className="block">
            Type <span className="text-red-500">*</span>
          </label>
          <select
            value={typeId}
            onChange={(e) => setTypeId(e.target.value)}
            required
            className="w-full border p-2"
          >
            <option value="">Select Type</option>
            {types.map((type) => (
              <option
                key={type.id}
                value={type.id}
                onChange={() => setTypeId(type.id)}
              >
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block">
            State <span className="text-red-500">*</span>
          </label>
          <select
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
            className="w-full border p-2"
          >
            <option value="">Select State</option>
            {statesData.regions.map((region) => (
              <option key={region.value} value={region.value}>
                {region.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block">
            City <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
            className="w-full border p-2"
          />
        </div>

        <div>
          <label className="block">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border p-2"
            maxLength="255"
          />
        </div>

        <div>
          <label className="block">
            Phone Number <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            className="w-full border p-2"
            maxLength="8"
            minLength="8"
          />
        </div>

        <div>
          {!isFree ? (
            <div>
              <label className="block">Price</label>
              <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                disabled={isFree}
                className={`w-full border p-2 ${isFree ? "bg-gray-200" : ""}`}
              />
            </div>
          ) : null}

          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              checked={isFree}
              onChange={() => setIsFree(!isFree)}
              className="mr-2"
            />
            <label>Is this free?</label>
          </div>
        </div>

        <button type="submit" className="w-full bg-black text-white p-2">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddPost;
