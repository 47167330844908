import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const PostAPI = {
  getAll: async function (page, params = {}, cancel = false) {
    let url = `api/posts?per_page=12&page=${page}`;
    console.log(params.state);
    if (
      params.state !== undefined &&
      params.state !== null &&
      params.state !== ""
    ) {
      url += `&state=${params.state}`;
    }

    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  create: async function (post, cancel = false) {
    const response = await api.request({
      url: `/api/posts`,
      method: "POST",
      data: post,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/categories/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(PostAPI);
