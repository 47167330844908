import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import HomePage from "../pages/home";
import AddPost from "../pages/addPost";
import AllPosts from "../pages/allposts";
const Platform = lazy(() => import("../layout/layout"));

const RoutesWenFiSakanApp = () => {
  return (
    <Router forceRefresh={false}>
      <Suspense fallback={<LinearProgress />}>
        <Routes>
          <Route path="/" element={<Platform />}>
            <Route index element={<HomePage />} />
            <Route path="/add-post" element={<AddPost />} />
            <Route path="/all-posts" element={<AllPosts />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RoutesWenFiSakanApp;
