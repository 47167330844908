import { useEffect, useState, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { HiDotsVertical } from "react-icons/hi"; // Importing an icon
import { PostAPI } from "../api/PostAPI";

const statesData = [
  { value: "Aakkar", label: "Aakkar" },
  { value: "Aaley", label: "Aaley" },
  { value: "Baabda", label: "Baabda" },
  { value: "Baalbek", label: "Baalbek" },
  { value: "Batroun", label: "Batroun" },
  { value: "Bcharreh", label: "Bcharreh" },
  { value: "Bent Jbeil", label: "Bent Jbeil" },
  { value: "Beqaa Ouest", label: "Beqaa Ouest" },
  { value: "Beyrouth", label: "Beyrouth" },
  { value: "Chouf", label: "Chouf" },
  { value: "Hasbaiya", label: "Hasbaiya" },
  { value: "Hermel", label: "Hermel" },
  { value: "Jbeil", label: "Jbeil" },
  { value: "Jezzine", label: "Jezzine" },
  { value: "Kesrouane", label: "Kesrouane" },
  { value: "Koura", label: "Koura" },
  { value: "Marjaayoun", label: "Marjaayoun" },
  { value: "Matn", label: "Matn" },
  { value: "Minieh-Danniyeh", label: "Minieh-Danniyeh" },
  { value: "Nabatiyeh", label: "Nabatiyeh" },
  { value: "Rachaiya", label: "Rachaiya" },
  { value: "Saida", label: "Saida" },
  { value: "Sour", label: "Sour" },
  { value: "Tripoli", label: "Tripoli" },
  { value: "Zahleh", label: "Zahleh" },
  { value: "Zgharta", label: "Zgharta" },
];

export default function AllPosts() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    per_page: null,
    total: null,
  });
  const [selectedState, setSelectedState] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State to control the menu visibility
  const [menuPostId, setMenuPostId] = useState(null); // Track the post for which the menu is open
  const menuRef = useRef(null); // Ref for the menu element
  const [parameters, setParameter] = useState({
    state: null,
    page: 1,
  });
  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const btmHeight = (offsetHeight * 20) / 100;
      const offsetHeightWithOutBtm = offsetHeight - btmHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom =
        offsetHeightWithOutBtm - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleDeleteClick = (post) => {
    setCurrentPost(post);
    setIsOpen(true);
  };

  const handleConfirmDelete = () => {
    console.log("Post deleted:", currentPost);
    setPosts(posts.filter((post) => post.id !== currentPost.id)); // Remove the post from state
    setIsOpen(false);
  };

  useEffect(() => {
    apiCall();
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const apiCall = () => {
    try {
      PostAPI.getAll(parameters.page).then((posts) => {
        setPosts(posts.data);
        setPagination({
          current_page: posts.current_page,
          total: posts.total,
          last_page: posts.last_page,
          per_page: posts.per_page,
        });
        // setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      PostAPI.getAll(1, parameters).then((post) => {
        setPosts(post.data);
        setPagination({
          current_page: post.current_page,
          total: post.total,
          last_page: post.last_page,
          per_page: post.per_page,
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, [parameters.state]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await PostAPI.getAll(parameters.page + 1, parameters).then((items) => {
          if (posts.length < items.total) {
            const getProducts = items.data;
            setPosts((prevProducts) => {
              const existingIds = new Set(
                prevProducts.map((product) => product.id)
              );
              const newProducts = getProducts.filter(
                (product) => !existingIds.has(product.id)
              );
              return [...prevProducts, ...newProducts];
            });

            setPagination({
              current_page: items.current_page,
              total: items.total,
              last_page: items.last_page,
              per_page: items.per_page,
            });
            setLoading(false);
            if (items.current_page < items.last_page) {
              setParameter({ ...parameters, page: parameters.page + 1 });
              setReachedBottom(false);
            } else {
              setLoadingProducts(false);
            }
          } else {
            setLoadingProducts(false);
            setLoading(false);
          }
        });
        setLoading(false);
      } catch (error) {}
    };
    if (reachedBottom) {
      console.log("reached");
      if (posts.length >= 12 && posts.length < pagination.total) {
        setLoadingProducts(true);
        setTimeout(() => {
          fetchProducts();
        }, 500);
      }
    }
  }, [reachedBottom]);
  return (
    <div className="max-w-7xl mx-auto p-6">
      <h1 className="text-4xl font-bold mb-4">All Posts</h1>

      <div className="mb-4">
        <label className="block mb-2">Filter by State</label>
        <select
          value={selectedState}
          onChange={(e) => {
            setSelectedState(e.target.value);

            setParameter({
              ...parameters,
              state: e.target.value,
            });
          }}
          className="lg:w-72 border p-2 rounded"
        >
          <option value="">All States</option>
          {statesData.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
        {posts.map((post) => (
          <div
            key={post.id}
            className="bg-white p-4 rounded-lg shadow relative border-2 border-gray-300"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold">{post.title}</h2>
              <div className="flex items-center">
                {post.isFree ? (
                  <span className="text-lg">Free</span>
                ) : post.price ? (
                  `$${post.price}`
                ) : null}
              </div>
            </div>
            <p className="text-gray-700 text-lg mb-2">{post.description}</p>
            <p className="text-sm text-gray-600">State: {post.state}</p>
            <p className="text-sm text-gray-600">City: {post.city}</p>
            {post.email ? (
              <p className="text-sm text-gray-600">Email: {post.email}</p>
            ) : null}
            <p className="text-sm text-gray-600">Phone: {post.phoneNumber}</p>
          </div>
        ))}
      </div>

      {loading && (
        <div className="flex justify-center items-center my-4">
          <span className="text-xl">Loading more posts...</span>
        </div>
      )}
      {loadingProducts && (
        <div className="flex mt-10 justify-center items-center ">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      )}
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="bg-white rounded-lg p-6">
            <Dialog.Title className="text-lg font-bold">
              Confirm Delete
            </Dialog.Title>
            <Dialog.Description className="mt-2">
              Are you sure you want to delete this post?
              <br />
              هل أنت متأكد أنك تريد حذف هذا المنشور؟
            </Dialog.Description>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={() => setIsOpen(false)}
                className="px-4 py-2 text-gray-700 border rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 text-white bg-red-500 rounded"
              >
                Confirm
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}
