import React from "react";
import AboutUs from "../components/about";
const HomePage = () => {
  return (
    <div>
      <AboutUs />
    </div>
  );
};

export default HomePage;
